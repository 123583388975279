<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="sousuo">
      <div class="div1">
        <span>菜单名称</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>部门</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字</span>
        <el-select
          style="width: 100px"
          class="select"
          v-model="value"
          placeholder="ID"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <input class="inputs" type="text" />
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
        <el-table-column
          prop="menuName"
          label="菜单名称"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="url"
          label="菜单链接路径"
          width="200"
        ></el-table-column>
        <el-table-column prop="level" label="菜单层级" width="90">
          <!-- <template slot-scope="scope">{{
            scope.row.level ? "一级菜单" : "二级菜单"
          }}</template> -->
        </el-table-column>
        <el-table-column
          prop="menuCode"
          label="菜单编码"
          width="180"
        ></el-table-column>
        <!-- <el-table-column prop="delFlag" label="删除标识" width="180">
          <template slot-scope="scope">{{
            scope.row.delFlag ? "有效" : "删除"
          }}</template>
        </el-table-column> -->
        <el-table-column prop="createDate" label="创建日期" width="180">
          <template slot-scope="scope">{{
            scope.row.createDate | dateFormatYYmmddHHMM
          }}</template>
        </el-table-column>
        <el-table-column prop="updateDate" label="修改日期" width="180">
          <template slot-scope="scope">{{
            scope.row.updateDate | dateFormatYYmmdd
          }}</template>
        </el-table-column>
        <el-table-column prop="caozuo" label="操作" width="230">
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/edits.png"
              @click="changeMenu(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts"
              src="../../assets/images/del.png"
              @click="handleEdit(scope.$index, scope.row)"
              alt
            />
            <el-button
              class="details"
              size="mini"
              @click="details(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总菜单数
          <span class="spanto">{{ totalCount }}</span
          >个
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="this.pageSize"
            layout="total, prev, pager, next"
            :total="this.totalCount"
          >
            <img class="lefts" src="../../assets/images/leftpage.png" alt />
            <img class="rights" src="../../assets/images/rightpage.png" alt />
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button type="text" @click="add">添加菜单</el-button>
        </div>
      </div>
    </div>

    <!-- 添加菜单弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearRuleForm"
      >
        <div class="selectOption">
          <el-select
            v-model="ruleForm.parentId"
            placeholder="请选择"
            @change="getValue(ruleForm.parentId)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.id"
              clearable
            >
            </el-option>
          </el-select>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="id" prop="name" v-if="isShow">
            <el-input v-model="ruleForm.id" style="width: 230px"></el-input>
          </el-form-item>
          <el-form-item label="序号" prop="name">
            <el-input
              v-model="ruleForm.sortCode"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单名称" prop="name">
            <el-input
              v-model="ruleForm.menuName"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单链接路径" prop="name">
            <el-input v-model="ruleForm.url" style="width: 230px"></el-input>
          </el-form-item>
          <el-form-item label="菜单小图标路径" prop="name">
            <el-input
              v-model="ruleForm.iconUrl"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="菜单层级" prop="name">
            <el-input v-model="ruleForm.level"></el-input>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearRuleForm">取 消</el-button>
          <el-button type="primary" @click="addMenuList">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 点击详情的弹框 -->
    <div class="account">
      <el-dialog
        title="菜单详情"
        :visible.sync="dialogVisible1"
        width="50%"
        :before-close="detailsX"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <!-- <el-form-item label="id" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.id"></el-input>
          </el-form-item> -->
          <el-form-item label="父级菜单名称 :" prop="name">
            <el-input v-model="ruleForm1.parentMenuName"></el-input>
          </el-form-item>
          <el-form-item label="菜单code编码 :" prop="name">
            <el-input v-model="ruleForm1.code"></el-input>
          </el-form-item>
          <el-form-item label="创建人名称 :" prop="name">
            <el-input v-model="ruleForm1.createName"></el-input>
          </el-form-item>
          <el-form-item label="修改人名称 :" prop="name">
            <el-input v-model="ruleForm1.updateName"></el-input>
          </el-form-item>
          <el-form-item label="删除标识 :" prop="name">
            <el-input v-model="ruleForm1.delFlag"></el-input>
          </el-form-item>
          <!-- <el-form-item label="创建人的ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.createId"></el-input>
          </el-form-item>
          <el-form-item label="修改人的ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.updateId"></el-input>
          </el-form-item> -->
          <el-form-item label="小图标路径 :" prop="name">
            <el-input v-model="ruleForm1.iconUrl"></el-input>
          </el-form-item>
          <el-form-item label="修改日期 :" prop="name">
            <el-input v-model="ruleForm1.updateDate"></el-input>
          </el-form-item>
          <el-form-item label="创建日期 :" prop="createDate">
            <el-input v-model="ruleForm1.createDate"></el-input>
          </el-form-item>
          <el-form-item label="菜单路径 :" prop="name">
            <el-input v-model="ruleForm1.url"></el-input>
          </el-form-item>
          <el-form-item label="菜单层级 :" prop="name">
            <el-input v-model="ruleForm1.level"></el-input>
          </el-form-item>
          <!-- <el-form-item label="父级ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.parentId"></el-input>
          </el-form-item> -->

          <el-form-item label="菜单名称 :" prop="name">
            <el-input v-model="ruleForm1.menuName"></el-input>
          </el-form-item>
          <el-form-item label="菜单编码 :" prop="name">
            <el-input v-model="ruleForm1.menuCode"></el-input>
          </el-form-item>
          <el-form-item label="排序号 :" prop="name">
            <el-input v-model="ruleForm1.sortCode"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsX">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import rolemanage from "../system_maintain/rolemanage";
// import addaccount from "../system_maintain/addaccount";
import base from "../../api1/config";
import { api } from "../../api1/config";

export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      dialogVisible: false,
      dialogVisible1: false,
      isShow: true,
      title: "添加菜单",
      ruleForm: {},
      rules: {
        ID: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      ruleForm1: {}, //详情数据
      cengjiList: [], //层级列表
      currentPage1: 1,
      pageSize: 10,
      totalCount: 1000,
      options: [],
      value: "",
      //   table
      tableData: [],
      pageCount: "", //网络请求的页码
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist(1);
    this.handleClick();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //添加菜单
    addMenuList() {
      //判断是添加按钮还是修改按钮
      if (this.title == "添加菜单") {
        this.title = "添加菜单";
        let { menuName, url, level, parentId, sortCode, iconUrl } =
          this.ruleForm;
        console.log(
          "0--",
          menuName,
          url,
          level,
          parentId,
          sortCode,
          iconUrl,
          this.ruleForm
        );
        this.$http({
          url: base.addMenu,
          params: this.ruleForm,
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            //把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            // 'Authorization': 'Bearer ' + that.token,
            token: JSON.parse(sessionStorage.getItem("token")),
          },
        }).then((res) => {
          console.log("添加菜单：", res.data);
          if (res.data.code === 200) {
            //隐藏弹框
            this.dialogVisible = false;
            //清空表单数据
            this.clearRuleForm();
            //添加成功
            this.$message({
              message: "恭喜你，添加菜单成功",
              type: "success",
            });
            //刷新页面数据
            this.menulist({ pageCount: this.currentPage1 });
          } else {
            this.$message.error("抱歉，添加菜单失败");
          }
        });
      } else {
        //console.log('修改菜单');
        if (this.ruleForm.parentId == "") {
          this.ruleForm.parentId = 0;
        }
        let { id, menuName, url, level, parentId, sortCode, iconUrl } =
          this.ruleForm;
        console.log(
          "修改之后的菜单：",
          menuName,
          url,
          level,
          parentId,
          sortCode
        );
        // 修改菜单
        this.$http({
          url: base.changeMenuList,
          params: { id, menuName, url, level, parentId, sortCode, iconUrl },
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            //把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            // 'Authorization': 'Bearer ' + that.token,
            token: JSON.parse(sessionStorage.getItem("token")),
          },
        }).then((result) => {
          console.log("修改菜单：", result.data);
          if (result.data.code === 200) {
            //修改成功
            this.$message({
              message: "恭喜你，修改菜单成功",
              type: "success",
            });
            //关闭弹框
            this.dialogVisible = false;
            //清空表单数据
            this.clearRuleForm();
            //刷新页面数据
            //console.log('ye:',this.currentPage1);
            this.menulist(this.currentPage1);
            // window.location.reload();
          }
        });
      }
    },
    //点击添加菜单按钮
    add() {
      this.dialogVisible = true;
      this.title = "添加菜单";
      this.isShow = false;
    },
    //菜单列表
    menulist(page) {
      api.getMenuList({ pageCount: page }).then((res) => {
        console.log("菜单：", res.data);
        if (res.data.code === 200) {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
          this.pageSize = res.data.result.page.pageSize;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //修改菜单
    changeMenu(index, row) {
      console.log(index, "行", row);
      this.title = "修改菜单";
      this.isShow = false;
      this.ruleForm = { ...row };
      if (this.ruleForm.parentId == 0) {
        this.ruleForm.parentId = "";
      }
      //console.log('修改之后：',this.ruleForm);
      this.dialogVisible = true;
    },
    //删除菜单
    handleEdit(index, row) {
      console.log(index, row);
      this.$confirm("是否删除此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.deleteMenu({ id: row.id }).then((res) => {
            console.log("删除菜单:", res.data);
            if (res.data.code == 200) {
              //删除成功
              this.$message({
                message: "恭喜你，删除菜单成功",
                type: "success",
              });
              //清空表单数据
              this.clearRuleForm();
              //刷新页面数据
              console.log("ye:", this.currentPage1);
              this.menulist(this.currentPage1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    //详情
    details(index, row) {
      this.dialogVisible1 = true;
      api.getDetailsMenu({ id: row.id }).then((res) => {
        console.log("菜单详情:", res.data);
        // let collectTime = res.data.result.createDate;
        // this.timeData = new Date(parseInt(collectTime)).toLocaleString().replace(/:\d{1,2}$/,' ')
        // console.log('时间：',this.timeData);
        if (res.data.code == 200) {
          let collectTime = res.data.result.createDate;
          this.timeData = new Date(parseInt(collectTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          console.log("时间：", this.timeData);
          let collTime = res.data.result.updateDate;
          this.timeDa = new Date(parseInt(collTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          res.data.result.createDate = this.timeData;
          res.data.result.updateDate = this.timeDa;
          this.ruleForm1 = res.data.result;
        }
      });
    },
    //关闭详情按钮
    detailsX() {
      this.dialogVisible1 = false;
    },
    //添加菜单的下拉框
    handleClick() {
      this.$http({
        url: base.getParentId,
        params: { level: 1 },
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          //把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
          // 'Authorization': 'Bearer ' + that.token,
          token: JSON.parse(sessionStorage.getItem("token")),
        },
      }).then((result) => {
        //console.log("获取ParentId--", result.data.result);
        if (result.data.code === 200) {
          this.options = result.data.result;
          // console.log('层级',this.cengjiList,result.data.result);
          // for (let i = 0; i < result.data.result.length; i++) {
          //   this.ruleForm.parentId = result.data.result[i].id;
          // }
        }
      });
    },
    //获取点击的value值
    getValue(value) {
      console.log("获取：", value);
      if (value === "") {
        this.ruleForm.parentId = "";
      } else {
        this.ruleForm.parentId = value;
      }
    },

    //清空表单
    clearRuleForm() {
      this.dialogVisible = false; //隐藏弹框
      this.ruleForm = {
        sortCode: "",
        menuName: "",
        url: "",
        level: "",
        parentId: "",
      };
      //单独清除下拉框
      this.value = "";
    },
    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.menulist(val);
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;
    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
        margin-top: 11px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 11px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;
        margin-left: 80px;
        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 100px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          .el-pager,
          .el-pager li {
            margin-top: 2px;
            color: #afafaf;
            min-width: 50px;
            font-size: 17px;
            font-weight: normal;
          }
          .el-pager li.active {
            color: #d78673;
          }
          .el-pager li:hover {
            color: #d78673;
          }
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  .el-input__inner {
    width: 97%;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  .el-form-item__label {
    margin-left: 60px;
  }
  // // 弹框
  // .el-dialog {
  //   border-radius: 18px;
  // }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  .account {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
    }
  }
  .selectOption {
    margin: 25px 130px;
  }
  // .el-input__inner /deep/ {
  //   text-align: left;
  // }
  // .el-dropdown {
  //   margin-left: 15px;
  // }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px;
    line-height: 33px;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>